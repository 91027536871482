<template>
  <div>
    <v-chart
      v-if="chartData.length > 0"
      class="chart"
      :option="option"
      autoresize
    />
    <el-empty
      v-else
      slot="empty"
      class="chart"
      :description="$t('general.NoData')"
    />
  </div>
</template>

<script>
import VChart from 'vue-echarts'
import { use } from 'echarts/core'
import { TooltipComponent, LegendComponent } from 'echarts/components'
import { PieChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'
import { getSportTypeRatio } from '@/api/dashboard'

use([TooltipComponent, LegendComponent, PieChart, CanvasRenderer])

export default {
  name: 'SportTypeRatioPieChart', // admin dashboard
  components: {
    'v-chart': VChart
  },
  data() {
    return {
      chartData: []
    }
  },
  computed: {
    option() {
      return {
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c} ({d}%)'
        },
        color: [
          '#c6bdc2',
          '#066471',
          '#00c2cb',
          '#bbddeb',
          '#ffc738', 
          '#51859e',
          '#77797b', 
          '#1e5146',
          '#007949',
          '#3bbc61',
          '#10a99d',
          '#6ca14c',
          '#9c94fa'
        ],
        series: [
          {
            name: '資料分類占比',
            type: 'pie',
            cursor: 'default',
            radius: '50%',
            // data: [
            //   { value: 486, name: '步行' },
            //   { value: 735, name: '跑步' },
            //   { value: 288, name: '騎車' },
            //   { value: 548, name: '有氧運動' },
            //   { value: 838, name: '重量訓練' }
            // ],
            data: this.chartData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
    }
  },
  async mounted() {
    try {
      await getSportTypeRatio().then((res) => {
        this.chartData = res.data[0].SportTypeRatio.filter((item) => {
          return item.values !== 0
        }).map((item) => {
          item.name = item.type_zh
          item.value = item.values
          return item
        })
      })
    } catch (error) {
      console.error('getSportTypeRatio-error: ', error)
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  height: 100%;
  width: 100%;
  min-height: 550px;
  ::v-deep canvas{
    cursor: default;
  }
}
</style>
