<template>
  <div>
    <v-chart
      v-if="chartData.length > 0"
      class="chart"
      :option="option"
      autoresize
    />
    <el-empty
      v-else
      slot="empty"
      class="chart"
      :description="$t('general.NoData')"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VChart from 'vue-echarts'
import { use } from 'echarts/core'
import { TooltipComponent, LegendComponent } from 'echarts/components'
import { PieChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'

use([TooltipComponent, LegendComponent, PieChart, CanvasRenderer])

export default {
  name: 'OrgSportRationPieChart',
  components: {
    'v-chart': VChart
  },
  props: {
    isPhone: {
      type: Boolean,
      default: false
    },
    sportTypeData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      chartData: [],
      hasData: false
    }
  },
  computed: {
    ...mapGetters(['language']),
    edgeDistanceWidth () {
      return this.isPhone ? '5' : '20'
    },
    option() {
      return {
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c} ({d}%)'
        },
        color: [
          '#c6bdc2',
          '#066471',
          '#00c2cb',
          '#bbddeb',
          '#ffc738', 
          '#51859e',
          '#77797b', 
          '#1e5146',
          '#007949',
          '#3bbc61',
          '#10a99d',
          '#6ca14c',
          '#9c94fa'
        ],
        series: [
          {
            type: 'pie',
            cursor: 'default',
            radius: '50%',
            data: this.chartData,
            label: {
              position: 'outer',
              alignTo: 'edge',
              edgeDistance: this.edgeDistanceWidth
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
    }
  },
  watch: {
    language() { this.updateChartData(this.sportTypeData) },
    sportTypeData: {
      immediate: true,
      handler(newVal, oldVal) {
        this.updateChartData(newVal)
      }
    }
  },
  mounted() {
    this.updateChartData(this.sportTypeData)
  },
  methods: {
    updateChartData(sportTypeData) {
      this.chartData = sportTypeData.map(item => {
        return { 
          value: item.value,
          name: this.$t(`mainType.${item.name}`)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  height: 100%;
  width: 100%;
  min-height: 400px;
  ::v-deep canvas{
    cursor: default;
  }
  @media (max-width: 576px){
    min-height: 300px;
  }
}
</style>
