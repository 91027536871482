<template>
  <el-row>
    <el-card 
      :body-style="{ padding: '0px' }"
      class="datatype-card"
      :class="['datatype-card',datatypeContainer]"
      shadow="hover"
    >
      <div class="section-h2 datatype-title">{{ datatypeTitle }}</div>
      <el-row class="datatype-body">
        <el-row>
          <el-col 
            :xs="{span:12}"
            :sm="{span:10}"
            :md="{span:8}"
            :lg="{span:6}"
          >
            <animated-number
              class="datatype-number"
              :value="rawDataCount"
              :format-value="formatToLocaleString"
              :duration="1000"
            />
          </el-col>
          <el-col   
            class="datatype-number-desc-container"
            :xs="{span:12}"
            :sm="{span:14}"
            :md="{span:16}"
            :lg="{span:18}"
          >
            <div class="datatype-number-desc-text">{{ $t(`Dashboard.UploadDataTimes`) }}</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col 
            :xs="{span:12}"
            :sm="{span:10}"
            :md="{span:8}"
            :lg="{span:6}"
          >
            <animated-number
              class="datatype-number"
              :value="sdvDataCount"
              :format-value="formatToLocaleString"
              :duration="1000"
            />
          </el-col>
          <el-col   
            class="datatype-number-desc-container"
            :xs="{span:12}"
            :sm="{span:14}"
            :md="{span:16}"
            :lg="{span:18}"
          >
            <div class="datatype-number-desc-text">{{ $t(`Dashboard.NumberOfDataProviders`) }}</div>
          </el-col>
        </el-row>
      </el-row>
    </el-card>
  </el-row>
</template>

<script>
import 'element-ui/lib/theme-chalk/display.css'
import { MemberRole, FormatNumber, Resize } from '@/Mixins'

import AnimatedNumber from 'animated-number-vue'

export default {
  name: 'ConsumerDatatypeCard',
  components: {
    AnimatedNumber
  },
  mixins: [MemberRole, FormatNumber, Resize],
  props: {
    datatypeContainer: {
      type: String,
      default: ''
    },
    datatypeTitle: {
      type: String,
      default: ''
    },
    rawDataCount: {
      type: Number,
      default: 0
    },
    sdvDataCount: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      
    }
  },
  mounted() {
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
@import "src/styles/index.scss";
.maintype-container{
    .datatype-title{
      margin: 0;
      line-height: 2.5;
      text-align: center;
      border-radius: 0 0 40px 40px;
    }
    .datatype-body{
      padding: 50px 80px 150px 80px;
      @media (max-width: 576px){
        padding: 30px;
      }
      .datatype-number{
        font-size: 3rem;
        padding-right: 30px;
        @media (max-width: 576px){
          font-size: 2rem;
        }
      }
      
      .datatype-number-desc-container{
        height: 70px;
        display: flex;
        @media (max-width: 576px){
          height: 35px;
        }
        .datatype-number-desc-text{
          display: flex;
          align-items: flex-end;
        }
      }
    }
    .chart-card{
      padding: 30px 300px 50px 300px;
      @media (max-width: 1200px){
        padding: 30px 100px 50px 100px;
      }
      @media (max-width: 576px){
        padding: 30px;
      }
    }
    .chart-title{
      text-align: center;
      font-size: 1.25rem;
      font-weight: bold;
    }
    .progress-title-block{
      line-height: 3;
    }
    .subinfo-text{
      font-size: 0.875rem;
      color: $text;
      line-height: 3;
    }
    .progress-totle-number{
      float: right;
      font-size: 2rem;
    }
  }
 .sport-container{
    .datatype-card{
      background:url(../../../assets/images/dashboard/consumer_sport.png) bottom right no-repeat;
      background-size: 30%;
      background-color: white;
      @media (max-width: 576px){
        background: none;
        background-color: white;
      }
    }
    .datatype-title{
      background: linear-gradient(to bottom, #FCDF9F, #ffb000);
    }
  }
  .physicalFitness-container{
    .datatype-card{
      background:url(../../../assets/images/dashboard/consumer_physicalFitness.png) bottom right no-repeat;
      background-size: 30%;
      background-color: white;
      @media (max-width: 576px){
        background: none;
        background-color: white;
      }
    }
    .datatype-title{
      background: linear-gradient(to bottom, #92e9ed, #00c2cb);
    }
  }
  .physiology-container{
    .datatype-card{
      background:url(../../../assets/images/dashboard/consumer_physiology.png) bottom right no-repeat;
      background-size: 30%;
      background-color: white;
      @media (max-width: 576px){
        background: none;
        background-color: white;
      }
    }
    .datatype-title{
      background: linear-gradient(to bottom, #d8f196, #95c11f);
    }
  }
</style>
