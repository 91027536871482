<template>
  <div class="Consumer-dashboard">
    <!-- 總覽 -->
    <el-row class="overview-block">
      <el-card :body-style="{ padding: '0px' }" class="overview-card" shadow="hover">
        <div>
          <div class="overview-unit">
            <el-row>
              <el-col 
                :xs="{span:14}"
                :sm="{span:18}"
                :lg="{span:20}"
              >
                <span class="overview-unit-text-span dask-pad-only">{{ $t(`Dashboard.UploadDataTimes`) }}</span>
                <div class="overview-unit-text phone-only">{{ $t(`Dashboard.UploadDataTimes`) }}</div>
              </el-col>
              <el-col 
                :xs="{span:10}"
                :sm="{span:6}"
                :lg="{span:4}"
              >
                <animated-number
                  class="overview-num"
                  :value="rawDataSUM"
                  :format-value="formatToLocaleString"
                  :duration="2000"
                />
              </el-col>
            </el-row>
          </div>
          <div class="overview-unit">
            <el-row>
              <el-col 
                :xs="{span:14}"
                :sm="{span:18}"
                :lg="{span:20}"
              >
                <span class="overview-unit-text-span dask-pad-only">{{ $t(`Dashboard.CumulativeNumberOfDataProviders`) }}</span>
                <div class="overview-unit-text phone-only">{{ $t(`Dashboard.CumulativeNumberOfDataProviders`) }}</div>
              </el-col>
              <el-col 
                :xs="{span:10}"
                :sm="{span:6}"
                :lg="{span:4}"
              >
                <animated-number
                  class="overview-num"
                  :value="userSUM"
                  :format-value="formatToLocaleString"
                  :duration="2000"
                />
              </el-col>
            </el-row>
          </div>
        </div>
      </el-card>
      <el-row class="overview-datatype-row" type="flex" justify="space-around">
        <el-col 
          :sm="{span:7}"
          :md="{span:6}"
          class="datatype-count sport-card"
        >
          <el-card :body-style="{ padding: '0px' }" class="overview-datatype-card">
            <el-row>
              <el-col class="overview-datatype-card-img" :span="8">
                <img :src="sportIcon" class="image" :alt="$t('Dashboard.SportsDataCharityService')">
              </el-col>
              <el-col class="overview-datatype-card-body" :span="16">
                <div>
                  {{ $t(`general.SportsRawData`) }}
                </div>
                <div class="datatype-number">
                  <animated-number
                    :value="sportRawDataCount"
                    :format-value="formatToLocaleString"
                    :duration="1000"
                  />
                </div>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
        <el-col 
          :sm="{span:7}"
          :md="{span:6}"
          class="datatype-count physicalFitness-card"
        >
          <el-card :body-style="{ padding: '0px' }" class="overview-datatype-card">
            <el-row>
              <el-col class="overview-datatype-card-img" :span="8">
                <img :src="physicalFitnessIcon" class="image" :alt="$t('Dashboard.SportsDataCharityService')">
              </el-col>
              <el-col class="overview-datatype-card-body" :span="16">
                <div>
                  {{ $t(`general.PhysicalFitnessRawData`) }}
                </div>
                <div class="datatype-number">
                  <animated-number
                    :value="PhyRawDataCount"
                    :format-value="formatToLocaleString"
                    :duration="1000"
                  />
                </div>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
        <el-col 
          :sm="{span:7}"
          :md="{span:6}"
          class="datatype-count physiology-card"
        >
          <el-card :body-style="{ padding: '0px' }" class="overview-datatype-card">
            <el-row>
              <el-col class="overview-datatype-card-img" :span="8">
                <img :src="physiologyIcon" class="image" :alt="$t('Dashboard.SportsDataCharityService')">
              </el-col>
              <el-col class="overview-datatype-card-body" :span="16">
                <div>
                  {{ $t(`general.PhysiologyRawData`) }}
                </div>
                <div class="datatype-number">
                  <animated-number
                    :value="bioRawDataCount"
                    :format-value="formatToLocaleString"
                    :duration="1000"
                  />
                </div>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
      </el-row>
    </el-row>
    <el-row :gutter="30" class="consumer-chart-container">
      <el-col
        :xs="{span:24}"
        :sm="{span:14}"
      >
        <el-card :body-style="{ padding: '0px' }" class="chart-card city-card" shadow="hover">
          <div class="chart-title">
            {{ $t(`Chart.Chart_City`) }}
          </div>
          <ChartCity
            :is-phone="isPhone"
            :city-num="cityNum"
          />
        </el-card>
      </el-col>
      <el-col
        :xs="{span:24}"
        :sm="{span:10}"
      >
        <el-card :body-style="{ padding: '0px' }" class="chart-card" shadow="hover">
          <div class="chart-title">
            {{ $t(`Chart.DataTypeRatio`) }}
          </div>
          <DatatypeRingChart
            :is-phone="isPhone"
            :data-type="dataType"
          />
        </el-card>
      </el-col>
    </el-row>
    <el-row class="divider-container">
      <el-divider>
        <img class="image" :src="consumerDivider">
      </el-divider>
    </el-row>
    <!-- 運動數據 -->
    <template v-if="showSport">
      <div class="maintype-container sport-container">
        <ConsumerDatatypeCard
          :datatype-title="$t(`general.SportsRawData`)"
          :datatype-container="'sport-container'"
          :raw-data-count="sportRawDataCount"
          :sdv-data-count="userSportSUM"
        />
        <el-row>
          <el-card :body-style="{ padding: '0px' }" class="chart-card" shadow="hover">
            <div class="chart-title">
              {{ $t(`Chart.Chart_Sport`) }}
            </div>
            <SportTypePieChart
              :is-phone="isPhone"
              :sport-tpye-num="sportTpyeNum"
            /> 
          </el-card>
        </el-row>
      </div>
    </template>
    <!-- 體適能數據 -->
    <template v-if="showPhysicalFitness">
      <el-divider class="phone-only" />
      <div class="maintype-container physicalFitness-container">
        <ConsumerDatatypeCard
          :datatype-title="$t(`general.PhysicalFitnessRawData`)"
          :datatype-container="'physicalFitness-container'"
          :raw-data-count="PhyRawDataCount"
          :sdv-data-count="userPhySUM"
        />
        <el-row>
          <el-card :body-style="{ padding: '0px' }" class="chart-card" shadow="hover">
            <div class="chart-title">
              {{ $t(`Chart.Chart_PhysicalFitnessType`) }}
            </div>
            <PhysicalFitnessTypePieChart
              :is-phone="isPhone"
              :physical-fitness-tpye-num="physicalFitnessTpyeNum"
            />
          </el-card>
        </el-row>
      </div>
    </template>
    
    <!-- 生理數據 -->
    <template v-if="showPhysiology">
      <el-divider class="phone-only" />
      <div class="maintype-container physiology-container">
        <ConsumerDatatypeCard
          :datatype-title="$t(`general.PhysiologyRawData`)"
          :datatype-container="'physiology-container'"
          :raw-data-count="bioRawDataCount"
          :sdv-data-count="userBioSUM"
        />
        <el-row>
          <el-card :body-style="{ padding: '0px' }" class="chart-card" shadow="hover">
            <div class="chart-title">
              {{ $t(`Chart.Chart_Physiology_Place`) }}
            </div>
            <PhysiologyPlacePieChart
              :is-phone="isPhone"
              :physiology-place-data="physiologyPlaceData"
            />
          </el-card>
        </el-row>
      </div>
    </template>
  </div>
</template>

<script>
import 'element-ui/lib/theme-chalk/display.css'
import { FormatNumber, Resize, StatisticData } from '@/Mixins'

import DatatypeRingChart from '../../../components/Chart/DatatypeRingChart.vue'
import SportTypePieChart from '../../../components/Chart/SportTypePieChart.vue'
import PhysicalFitnessTypePieChart from '../../../components/Chart/PhysicalFitnessTypePieChart.vue'

import PhysiologyPlacePieChart from '../../../components/Chart/PhysiologyPlacePieChart.vue'
import ChartCity from '../../../components/Chart/ChartCity.vue'

import ConsumerDatatypeCard from './ConsumerDatatypeCard.vue'

import { getDataCount } from '@/api/data'
import { getMainTypeRecord, getPlaceRatio } from '@/api/dashboard'

import AnimatedNumber from 'animated-number-vue'

export default {
  name: 'ConsumerDashboard',
  components: {
    AnimatedNumber,
    ChartCity,
    DatatypeRingChart,
    SportTypePieChart,
    PhysiologyPlacePieChart,
    PhysicalFitnessTypePieChart,
    ConsumerDatatypeCard
  },
  mixins: [FormatNumber, Resize, StatisticData],
  data () {
    return {
      rawDataSUM: 0,
      sportRawDataCount: 0,
      PhyRawDataCount: 0,
      bioRawDataCount: 0,

      userSUM: 0,
      userSportSUM: 0,
      userPhySUM: 0,
      userBioSUM: 0,

      physiologyPercentage: 0,
      physicalFitnessPercentage: 0,
      sportPercentage: 0,
      datatypeTotalcard: [
        {
          title: '運動數據',
          dataType: 'sport'
        }
      ],

      // chart
      cityNum: [],
      dataType: [],
      isPhone: false,
      physiologyPlaceData: [],
      physicalFitnessTpyeNum: {},
      sportTpyeNum: {},

      healthColor: '#95c11f',
      physicalFitnessColor: '#00c2cb',
      sportColor: '#ffb000',
      sportIcon: require('@/assets/images/dashboard/provider_data_count_sport.png'),
      physicalFitnessIcon: require('@/assets/images/dashboard/provider_data_count_physicalFitness.png'),
      physiologyIcon: require('@/assets/images/dashboard/provider_data_count_physiology.png'),
      consumerDivider: require('@/assets/images/dashboard/consumer_divider.png')
    }
  },
  computed: {
    showSport() {
      return this.sportRawDataCount
    },
    showPhysicalFitness() {
      return this.PhyRawDataCount
    },
    showPhysiology() {
      return this.bioRawDataCount
    }
  },
  mounted() {
    this.loadData()
    this.getTypeRecord()
  },
  methods: {
    loadData() {
      this.getSummaryData()
      this.getPlaceRecord()
      this.$watch('statistic', this.setStatisticNum)
    },
    async getSummaryData() {
      try {
        const res = await getDataCount()
      
        // for this component
        this.sportRawDataCount = res.data.Sport ? res.data.Sport : 0
        this.PhyRawDataCount = res.data.PhysicalFitness ? res.data.PhysicalFitness : 0
        this.bioRawDataCount = res.data.Physiology ? res.data.Physiology : 0
        this.rawDataSUM = res.data.all_count ? res.data.all_count : 0

        // for chart
        const dataType = res.data
        
        this.dataType = Object.entries(dataType
        ).filter((
          [key, value]
        ) => key !== 'all_count')
      } catch (error) {
        console.error('getDataCount-error: ', error)
      }
    },
    setStatisticNum() {
      const providerStatistic = this.statistic.provider

      this.userSUM = providerStatistic.all_count
      this.userSportSUM = providerStatistic.Sport
      this.userPhySUM = providerStatistic.PhysicalFitness
      this.userBioSUM = providerStatistic.Physiology
      this.cityNum = this.statistic.city
    },
    async getTypeRecord() {
      try {
        const res = await getMainTypeRecord()
        const res_PhysicalFitness = res.data.find(item => item.PhysicalFitness)
        const res_Sport = res.data.find(item => item.Sport)
        this.physicalFitnessTpyeNum = res_PhysicalFitness.PhysicalFitness[0]
        this.sportTpyeNum = res_Sport.Sport[0]
      } catch (error) {
        console.error(error)
      }
    },
    async getPlaceRecord() {
      try {
        const res = await getPlaceRatio()
        const apiResData = res.data
      
        this.physiologyPlaceData = this.filterChartData(apiResData.Physiology)
      } catch (error) {
        console.error(error)
      }
    },
    filterChartData(item) {
      const result = item.filter((item) => {
        return item.value !== 0
      }).map((item) => {
        item.name = this.$t(`PlaceType.${item.place}`)
        return item
      })
      return result
    },
    handleResize() {
      this.$nextTick(() => {
        this.isPhone = window.innerWidth < 768
      })
    }
  }
}

</script>

<style scoped lang="scss">
@import "src/styles/index.scss";

.Consumer-dashboard{
  margin: 0 auto;
  max-width: 1200px;
  text-align: left;
  font-size: 1rem;
  .dask-pad-only{
    @include hidden-by-phone;
  }
  .phone-only{
    @include hidden-by-desk;
    @include hidden-by-pad;
  }
  .el-card{
    border: none;
    margin: 15px 0;
  }
  .overview-block{
    position: relative;
    margin-bottom: 50px;
    .overview-card{
      text-align: right;
      background:url(../../../assets/images/dashboard/consumer_dashboard_overview.png) bottom left no-repeat;
      background-size: 25%;
      background-color: white;
      padding: 50px;
      height: 260px;
      @media (max-width: 576px){
        padding: 20px;
        height: initial;
      }

      .overview-desc, .overview-unit{
        text-align: right;
        font-size: 1.5rem;
        .overview-unit-text-span, .overview-unit-text{
          font-size: 0.875rem;
        }
      }
      .overview-num{
        font-size: 2rem;
      }
      @media (max-width: 576px) { 
        .overview-desc, .overview-unit{
          text-align: center;
        }
        .overview-desc{
          padding-bottom: 30px;
        }
      }
      @media (max-width: 576px) { 
        .overview-unit-text{
          font-size: 0.875rem;
        }
      }
    }
    @media (max-width: 1200px) and (min-width: 769px){
      .overview-card{
        background-size: 30%;
      }
    }
    @media (max-width: 768px) {
      .overview-card{
        background:none;
        background-color: white;
      }
    }
    .overview-datatype-row{
      position: absolute;
      bottom: -50px;
      width: 100%;
      .sport-card{
        .overview-datatype-card-body{
          background-color: $sport_yellow;
        }
      }
      .physicalFitness-card{
        .overview-datatype-card-body{
          background-color: $physicalFitness_turquoise;
        }
      }
      .physiology-card{
        .overview-datatype-card-body{
          background-color: $physiology_green;
        }
      }
      .datatype-count, .el-card{
        border-radius: 5px;
        .overview-datatype-card{
          margin: 0;
        }
        .overview-datatype-card-img{
          background-color: white;
          text-align: center;
          .image{
            width: 92%;
            margin:auto;
            display: block;
            @media (max-width: 768px) and (min-width: 577px){
              width: 40%;
            }
            @media (max-width: 576px) and (min-width: 411px){
              width: 60%;
            }
            @media (max-width: 410px) {
              width: 90%;
            }
          }
        }
        .overview-datatype-card-body{
          padding: 20px;
          font-size: 1rem;
          .datatype-number{
            padding: 5px 0;
            font-size: 2rem;
          }
        }
        @media (max-width: 1200px) and (min-width: 769px){
          .overview-datatype-card-body{
            padding: 20px 10px;
          }
        }
      }
    }
    @media (max-width: 768px) {
      .overview-datatype-row{
        position: initial;
        display: block;
        .datatype-count .overview-datatype-card{
          margin-bottom: 10px;
        }
      }
    }
  }
  .consumer-chart-container{
    .chart-card{
      padding: 50px;
      padding-top: 30px;
      @media (max-width: 576px){
        padding: 30px;
      }
    }
    .chart-title{
      text-align: center;
      font-size: 1.25rem;
      font-weight: bold;
    }
    .city-card{
      background:url(../../../assets/images/dashboard/taiwan.png) bottom left no-repeat;
      background-size: 20%;
      background-color: white;
    }
  }

  .maintype-container{
    .chart-card{
      padding: 30px 300px 50px 300px;
      @media (max-width: 1200px){
        padding: 30px 100px 50px 100px;
      }
      @media (max-width: 576px){
        padding: 30px;
      }
    }
    .chart-title{
      text-align: center;
      font-size: 1.25rem;
      font-weight: bold;
    }
  }
  .divider-container{
    height: 150px;
    .el-divider{
      top: 50px;
    }
    @media (max-width: 410px) {
      height: 100px;
      .el-divider{
        top: 30px;
      }
    }
    .el-divider__text{
      padding: 0;
      background-color: transparent;
      .image{
        width: 70%;
        margin:auto;
        display: block;
        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }
  }
}
</style>
