<template>
  <div class="chart-container">
    <v-chart
      class="chart"
      :option="option"
      autoresize
    />
  </div>
</template>

<script>
import VChart from 'vue-echarts'
import { use } from 'echarts/core'
import { TooltipComponent, LegendComponent } from 'echarts/components'
import { PieChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'

use([TooltipComponent, LegendComponent, PieChart, CanvasRenderer])

export default {
  name: 'PhysicalFitnessTypePieChart', // consumer dashboard
  components: {
    'v-chart': VChart
  },
  props: {
    physicalFitnessTpyeNum: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      chartData: []
    }
  },
  computed: {
    edgeDistanceWidth () {
      return this.isPhone ? '5' : '20'
    },
    option() {
      return {
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c} ({d}%)',
          position: [10, 150]
        },
        color: [
          '#c6bdc2',
          '#066471',
          '#00c2cb',
          '#bbddeb',
          '#ffc738', 
          '#51859e',
          '#77797b', 
          '#1e5146',
          '#007949',
          '#3bbc61',
          '#10a99d',
          '#6ca14c',
          '#9c94fa'
        ],
        series: [
          {
            name: '場域資料占比',
            type: 'pie',
            cursor: 'default',
            radius: '50%',
            data: this.chartData,
            label: {
              position: 'outer',
              alignTo: 'edge',
              edgeDistance: this.edgeDistanceWidth
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
    }
  },
  watch: {
    physicalFitnessTpyeNum(newVal) {
      this.updateChartData(newVal)
    }
  },
  mounted() {
    this.updateChartData(this.physicalFitnessTpyeNum)
  },
  methods: {
    updateChartData(physicalFitnessTpyeNum) {
      this.chartData = Object.entries(physicalFitnessTpyeNum).map(([name, value]) => ({
        name: this.$t(`mainType.${name}`),
        value
      }))
    }
  }
}
</script>

<style scoped lang="scss">
.chart-container{
  // text-align: center;
  .chart {
    height: 100%;
    width: 100%;
    min-height: 400px; // 200 fhashion
    ::v-deep canvas{
      cursor: default;
    }
    @media (max-width: 576px){
      min-height: 300px;
    }
  }
}

</style>
