<template>
  <div>
    <v-chart
      class="chart"
      :option="option"
      autoresize
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VChart from 'vue-echarts'
import { use } from 'echarts/core'
import { TooltipComponent, LegendComponent } from 'echarts/components'
import { PieChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'

use([TooltipComponent, LegendComponent, PieChart, CanvasRenderer])

export default {
  name: 'ChartCity', // for consumer dashboard
  components: {
    'v-chart': VChart
  },
  props: {
    cityNum: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      chartData: []
    }
  },
  computed: {
    ...mapGetters(['language']),
    edgeDistanceWidth () {
      return this.isPhone ? '5' : '60' // 儀錶板沒有做
    },
    option() {
      return {
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c} ({d}%)'
          // position: [10, 150]
        },
        color: [
          '#c6bdc2',
          '#066471',
          '#00c2cb',
          '#bbddeb',
          '#ffc738', 
          '#51859e',
          '#77797b', 
          '#1e5146',
          '#007949',
          '#3bbc61',
          '#10a99d',
          '#6ca14c',
          '#9c94fa'
        ],
        series: [
          {
            name: '來源縣市',
            type: 'pie',
            cursor: 'default',
            radius: ['40%', '70%'],
            avoidLabelOverlap: true, //  延伸線
            data: this.chartData,
            // data: [
            //   { value: 4831, name: '彰化縣' },
            //   { value: 2598, name: '高雄市' },
            //   { value: 1563, name: '嘉義市' },
            //   { value: 967, name: '桃園市' },
            //   { value: 892, name: '新北市' },
            //   { value: 784, name: '雲林縣' },
            //   { value: 763, name: '嘉義縣' },
            //   { value: 560, name: '臺北市' },
            //   { value: 3744, name: '未填' },
            //   { value: 1323, name: '其他' }
            // ],
            label: {
              position: 'outer',
              alignTo: 'edge',
              edgeDistance: this.edgeDistanceWidth
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
    }
  },
  watch: {
    language() { this.updateChartData(this.cityNum) },
    cityNum: {
      immediate: true,
      handler(newVal, oldVal) {
        this.updateChartData(newVal)
      }
    }
  },
  mounted() {
    this.updateChartData(this.cityNum)
  },
  methods: {
    updateChartData(cityNum) {
      this.chartData = cityNum.map(item => {
        return { 
          value: item.value,
          name: this.$t(`City.${item.key}`)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  height: 100%;
  width: 100%;
  min-height: 300px; // 200 fhashion
  ::v-deep canvas{
    cursor: default; // important 無效
  }
  @media (max-width: 576px){
    min-height: 200px;
  }
}
</style>
