<template>
  <div>
    <v-chart
      v-if="chartData.length > 0"
      class="chart"
      :option="option"
      autoresize
    />
    <el-empty
      v-else
      slot="empty"
      class="chart"
      :description="$t('general.NoData')"
    />
  </div>
</template>

<script>
import VChart from 'vue-echarts'
import { use } from 'echarts/core'
import { TooltipComponent, LegendComponent } from 'echarts/components'
import { PieChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'

use([TooltipComponent, LegendComponent, PieChart, CanvasRenderer])

export default {
  name: 'HealthUploadRatioPieChart', // admin dashboard
  components: {
    'v-chart': VChart
  },
  props: {
    physiologyUploadData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      chartData: []
    }
  },
  computed: {
    option() {
      return {
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c} ({d}%)'
        },
        color: [
          '#c6bdc2',
          '#066471',
          '#00c2cb',
          '#bbddeb',
          '#ffc738', 
          '#51859e',
          '#77797b', 
          '#1e5146',
          '#007949',
          '#3bbc61',
          '#10a99d',
          '#6ca14c',
          '#9c94fa'
        ],
        series: [
          {
            name: '上傳數據占比',
            type: 'pie',
            cursor: 'default',
            radius: '50%',
            // data: [
            //   { value: 547, name: '中正運動中心' },
            //   { value: 735, name: '長庚醫院' },
            //   { value: 957, name: '松山健康中心' }
            // ],
            data: this.chartData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
    }
  },
  watch: {
    physiologyUploadData: {
      immediate: true,
      handler(newVal, oldVal) {
        this.chartData = newVal
      }
    }
  },
  mounted() {
    this.chartData = this.physiologyUploadData
  }
}
</script>

<style lang="scss" scoped>
.chart {
  height: 100%;
  width: 100%;
  min-height: 550px;
  ::v-deep canvas{
    cursor: default;
  }
}
</style>
