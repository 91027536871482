import { render, staticRenderFns } from "./ProviderDatatypeCard.vue?vue&type=template&id=6ee218ff&scoped=true&"
import script from "./ProviderDatatypeCard.vue?vue&type=script&lang=js&"
export * from "./ProviderDatatypeCard.vue?vue&type=script&lang=js&"
import style0 from "./ProviderDatatypeCard.vue?vue&type=style&index=0&id=6ee218ff&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ee218ff",
  null
  
)

export default component.exports