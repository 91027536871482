<template>
  <div>
    <v-chart
      v-if="chartData.length > 0"
      class="chart"
      :option="option"
      autoresize
    />
    <el-empty
      v-else
      slot="empty"
      class="chart"
      :description="$t('general.NoData')"
    />
  </div>
</template>

<script>
import VChart from 'vue-echarts'
import { use } from 'echarts/core'
import { TooltipComponent, LegendComponent } from 'echarts/components'
import { PieChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'

use([TooltipComponent, LegendComponent, PieChart, CanvasRenderer])

export default {
  name: 'SportTypePieChart', // consumer dashboard
  components: {
    'v-chart': VChart
  },
  props: {
    isPhone: {
      type: Boolean,
      default: false
    },
    sportTpyeNum: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      chartData: []
    }
  },
  computed: {
    edgeDistanceWidth () {
      return this.isPhone ? '5' : '20'
    },
    option() {
      return {
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c} ({d}%)',
          position: [10, 150]
        },
        color: [
          '#c6bdc2',
          '#066471',
          '#00c2cb',
          '#bbddeb',
          '#ffc738', 
          '#51859e',
          '#77797b', 
          '#1e5146',
          '#007949',
          '#3bbc61',
          '#10a99d',
          '#6ca14c',
          '#9c94fa'
        ],
        series: [
          {
            name: '資料分類占比',
            type: 'pie',
            cursor: 'default',
            radius: '50%',
            // data: [
            //   { value: 147701, name: '騎車' },
            //   { value: 116758, name: '跑步' },
            //   { value: 75163, name: '健行' },
            //   { value: 10575, name: '游泳' },
            //   { value: 2062, name: '棒球' },
            //   { value: 292, name: '步行' }
            // ],
            data: this.chartData,
            label: {
              position: 'outer',
              alignTo: 'edge',
              edgeDistance: this.edgeDistanceWidth
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
    }
  },
  watch: {
    sportTpyeNum(newVal) {
      this.updateChartData(newVal)
    }
  },
  mounted() {
    this.updateChartData(this.sportTpyeNum)
  },
  methods: {
    updateChartData(sportTpyeNum) {
      this.chartData = Object.entries(sportTpyeNum).map(([name, value]) => ({
        name: this.$t(`mainType.${name}`),
        value
      }))
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  height: 100%;
  width: 100%;
  min-height: 400px; // 200 fhashion
  ::v-deep canvas{
    cursor: default;
  }
  @media (max-width: 576px){
    min-height: 300px;
  }
}
</style>
