<template>
  <div class="Provider-dashboard">
    <!-- 總覽 -->
    <el-row class="overview-block">
      <el-card :body-style="{ padding: '0px' }" class="overview-card" shadow="hover">
        <div>
          <div class="overview-desc dask-pad-only">
            {{ $t(`Dashboard.Thanks`) }}, 
            <span>{{ orgName }}</span>
          </div>
          <div class="overview-desc phone-only">
            <div>{{ orgName }}</div>
            <div>{{ $t(`Dashboard.Thanks`) }}</div>
          </div>
          
          <div class="overview-unit">
            <template v-if="showData">
              <span class="overview-unit-text-span dask-pad-only">{{ $t(`Dashboard.DataProvide`) }}</span>
              <div class="overview-unit-text phone-only">{{ $t(`Dashboard.DataProvide`) }}</div>
              <animated-number
                class="overview-num"
                :value="allCount"
                :format-value="formatToLocaleString"
                :duration="2000"
              />
            </template>
            <template v-else>
              <span class="overview-unit-text-span dask-pad-only">{{ $t(`Dashboard.WelcomeProviderGreeting`) }}</span>
              <div class="overview-unit-text phone-only">{{ $t(`Dashboard.WelcomeProviderGreeting`) }}</div>
            </template>
          </div>
          
        </div>
      </el-card>
      <el-row v-if="showData" class="overview-datatype-row" type="flex" justify="space-around">
        <el-col 
          :sm="{span:7}"
          :md="{span:6}"
          class="datatype-count sport-card"
        >
          <el-card :body-style="{ padding: '0px' }" class="overview-datatype-card">
            <el-row>
              <el-col class="overview-datatype-card-img" :span="8">
                <img :src="sportIcon" class="image" :alt="$t('Dashboard.SportsDataCharityService')">
              </el-col>
              <el-col class="overview-datatype-card-body" :span="16">
                <div>{{ $t(`general.SportsRawData`) }}</div>
                <div class="datatype-number">
                  <animated-number
                    :value="sport"
                    :format-value="formatToLocaleString"
                    :duration="2000"
                  />
                </div>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
        <el-col 
          :sm="{span:7}"
          :md="{span:6}"
          class="datatype-count physicalFitness-card"
        >
          <el-card :body-style="{ padding: '0px' }" class="overview-datatype-card">
            <el-row>
              <el-col class="overview-datatype-card-img" :span="8">
                <img :src="physicalFitnessIcon" class="image" :alt="$t('Dashboard.SportsDataCharityService')">
              </el-col>
              <el-col class="overview-datatype-card-body" :span="16">
                <div>{{ $t(`general.PhysicalFitnessRawData`) }}</div>
                <div class="datatype-number">
                  <animated-number
                    :value="physicalFitness"
                    :format-value="formatToLocaleString"
                    :duration="2000"
                  />
                </div>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
        <el-col
          :sm="{span:7}"
          :md="{span:6}"
          class="datatype-count physiology-card"
        >
          <el-card :body-style="{ padding: '0px' }" class="overview-datatype-card">
            <el-row>
              <el-col class="overview-datatype-card-img" :span="8">
                <img :src="physiologyIcon" class="image" :alt="$t('Dashboard.SportsDataCharityService')">
              </el-col>
              <el-col class="overview-datatype-card-body" :span="16">
                <div>{{ $t(`general.PhysiologyRawData`) }}</div>
                <div class="datatype-number">
                  <animated-number
                    :value="physiology"
                    :format-value="formatToLocaleString"
                    :duration="2000"
                  />
                </div>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
      </el-row>
    </el-row>
    <!-- 運動數據 -->
    <template v-if="showSport">
      <el-divider class="phone-only" />
      <div class="maintype-container sport-container">
        <ProviderDatatypeCard
          :datatype-title="`${$t(`general.SportsRawData`)}`"
          :datatype-container="'sport-container'"
          :provide-data-count="sport"
          :provide-percentage="sportPercentage"
          :datatype-color="sportColor"
        />
        <el-row :gutter="30">
          <el-col 
            :xs="{span:24}"
            :sm="{span:24}"
            :md="{span:12}"
          >
            <el-card :body-style="{ padding: '0px' }" class="chart-card" shadow="hover">
              <div class="chart-title">
                {{ $t(`Chart.Chart_Sport`) }}
              </div>
              <OrgSportRationPieChart
                :is-phone="isPhone"
                :sport-type-data="sportTypeData"
              />
              <ProviderChartPercentageBar
                :chart-percentage-title="`${$t(`Dashboard.MainTypeRatio`)}`"
                :chart-num="sportTypeNUM"
                :percentage-num="sportTypePercentage"
                :datatype-color="sportColor"
                :numerator-text="`${$t(`Dashboard.UploadMainType`)}`"
                :denominator-text="`${$t(`Dashboard.ServiceMainType`)}`"
                :unit-text="`${$t(`Dashboard.Amount`)}`"
              />
            </el-card>
          </el-col>
          <el-col
            :xs="{span:24}"
            :sm="{span:24}"
            :md="{span:12}"
          >
            <el-card :body-style="{ padding: '0px' }" class="chart-card" shadow="hover">
              <div class="chart-title">
                {{ $t(`Chart.Chart_Sport_Place`) }}
              </div>
              <SportPlacePieChart
                :is-phone="isPhone"
                :sport-place-data="sportPlaceData"
              />
              <ProviderChartPercentageBar
                :chart-percentage-title="`${$t(`Dashboard.PlaceTypeRatio`)}`"
                :chart-num="sportPlaceNUM"
                :percentage-num="sportPlacePercentage"
                :datatype-color="sportColor"
                :numerator-text="`${$t(`Dashboard.UploadPlaceType`)}`"
                :denominator-text="`${$t(`Dashboard.ServicePlaceType`)}`"
                :unit-text="`${$t(`Dashboard.Amount`)}`"
              />
            </el-card>
          </el-col>
        </el-row>
      </div>
    </template>
    <!-- 體適能數據 -->
    <template v-if="showPhysicalFitness">
      <el-divider class="phone-only" />
      <div class="maintype-container physicalFitness-container">
        <ProviderDatatypeCard
          :datatype-title="`${$t(`general.PhysicalFitnessRawData`)}`"
          :datatype-container="'physicalFitness-container'"
          :provide-data-count="physicalFitness"
          :provide-percentage="physicalFitnessPercentage"
          :datatype-color="physicalFitnessColor"
        />
        <el-row :gutter="30">
          <el-col
            :xs="{span:24}"
            :sm="{span:24}"
            :md="{span:12}"
          >
            <el-card :body-style="{ padding: '0px' }" class="chart-card" shadow="hover">
              <div class="chart-title">
                {{ $t(`Chart.Chart_PhysicalFitnessType`) }}
              </div>
              <OrgPhysicalFitnessRationPieChart
                :is-phone="isPhone"
                :physical-fitness-type-data="physicalFitnessTypeData"
              />
              <ProviderChartPercentageBar
                :chart-percentage-title="`${$t(`Dashboard.MainTypeRatio`)}`"
                :chart-num="physicalFitnessTpyeNUM"
                :percentage-num="physicalFitnessTpyePercentage"
                :datatype-color="physicalFitnessColor"
                :numerator-text="`${$t(`Dashboard.UploadMainType`)}`"
                :denominator-text="`${$t(`Dashboard.ServiceMainType`)}`"
                :unit-text="`${$t(`Dashboard.Amount`)}`"
              />
            </el-card>
          </el-col>
          <el-col
            :xs="{span:24}"
            :sm="{span:24}"
            :md="{span:12}"
          >
            <el-card :body-style="{ padding: '0px' }" class="chart-card" shadow="hover">
              <div class="chart-title">
                {{ $t(`Chart.Chart_PhysicalFitness_Place`) }}
              </div>
              <PhysicalFitnessPlacePieChart
                :is-phone="isPhone"
                :physical-fitness-place-data="physicalFitnessPlaceData"
              />
              <ProviderChartPercentageBar
                :chart-percentage-title="`${$t(`Dashboard.PlaceTypeRatio`)}`"
                :chart-num="physicalFitnessPlaceNUM"
                :percentage-num="physicalPlaceFitnessPercentage"
                :datatype-color="physicalFitnessColor"
                :numerator-text="`${$t(`Dashboard.UploadPlaceType`)}`"
                :denominator-text="`${$t(`Dashboard.ServicePlaceType`)}`"
                :unit-text="`${$t(`Dashboard.Amount`)}`"
              />
            </el-card>
          </el-col>
        </el-row>
      </div>
    </template>
    <!-- 生理數據 -->
    <template v-if="showPhysiology">
      <el-divider class="phone-only" />
      <div class="maintype-container physiology-container">
        <ProviderDatatypeCard
          :datatype-title="`${$t(`general.PhysiologyRawData`)}`"
          :datatype-container="'physiology-container'"
          :provide-data-count="physiology"
          :provide-percentage="physiologyPercentage"
          :datatype-color="physiologyColor"
        />
        <el-row :gutter="30">
          <el-col :span="24">
            <el-card :body-style="{ padding: '0px' }" class="chart-card" shadow="hover">
              <div class="chart-title">
                {{ $t(`Chart.Chart_Physiology_Place`) }}
              </div>
              <PhysiologyPlacePieChart
                :is-phone="isPhone"
                :physiology-place-data="physiologyPlaceData"
              />
              <ProviderChartPercentageBar
                :chart-percentage-title="`${$t(`Dashboard.PlaceTypeRatio`)}`"
                :chart-num="physiologyPlaceNUM"
                :percentage-num="physiologyPlacePercentage"
                :datatype-color="physiologyColor"
                :numerator-text="`${$t(`Dashboard.UploadPlaceType`)}`"
                :denominator-text="`${$t(`Dashboard.ServicePlaceType`)}`"
                :unit-text="`${$t(`Dashboard.Amount`)}`"
              />
            </el-card>
          </el-col>
        </el-row>
      </div>
    </template>
  </div>
</template>

<script>
import 'element-ui/lib/theme-chalk/display.css'
import { MemberRole, FormatNumber, Resize } from '@/Mixins'

import OrgPhysicalFitnessRationPieChart from '../chart/OrgPhysicalFitnessRationPieChart.vue'
import OrgSportRationPieChart from '../chart/OrgSportRationPieChart.vue'

import SportPlacePieChart from '../../../components/Chart/SportPlacePieChart.vue'
import PhysiologyPlacePieChart from '../../../components/Chart/PhysiologyPlacePieChart.vue'
import PhysicalFitnessPlacePieChart from '../../../components/Chart/PhysicalFitnessPlacePieChart.vue'

import ProviderDatatypeCard from './ProviderDatatypeCard.vue'
import ProviderChartPercentageBar from './ProviderChartPercentageBar.vue'

import { getProviderDataCount } from '@/api/data'
import { getMainTypeRecord, getPlaceRatio } from '@/api/dashboard'
import AnimatedNumber from 'animated-number-vue'

export default {
  name: 'ProviderDashboard',
  components: {
    AnimatedNumber,
    OrgPhysicalFitnessRationPieChart,
    OrgSportRationPieChart,
    SportPlacePieChart,
    PhysiologyPlacePieChart,
    PhysicalFitnessPlacePieChart,
    ProviderDatatypeCard,
    ProviderChartPercentageBar
  },
  mixins: [MemberRole, FormatNumber, Resize],
  data () {
    return {
      physiology: 0,
      physicalFitness: 0,
      sport: 0,
      allCount: 0,

      sportPercentage: 0,
      sportTypePercentage: 0,
      sportTypeNUM: 0,
      sportPlaceNUM: 0,
      sportPlacePercentage: 0,

      physicalFitnessPercentage: 0,
      physicalFitnessTpyePercentage: 0,
      physicalFitnessTpyeNUM: 0,
      physicalFitnessPlaceNUM: 0,
      physicalPlaceFitnessPercentage: 0,
      
      physiologyPercentage: 0,
      physiologyPlacePercentage: 0,
      physiologyPlaceNUM: 0,

      // for place chart
      sportPlaceData: [],
      physicalFitnessPlaceData: [],
      physiologyPlaceData: [],
      sportTypeData: [],
      physicalFitnessTypeData: [],

      isPhone: false,
      physiologyColor: '#95c11f',
      physicalFitnessColor: '#00c2cb',
      sportColor: '#ffb000',
      sportIcon: require('@/assets/images/dashboard/provider_data_count_sport.png'),
      physicalFitnessIcon: require('@/assets/images/dashboard/provider_data_count_physicalFitness.png'),
      physiologyIcon: require('@/assets/images/dashboard/provider_data_count_physiology.png')
    }
  },
  computed: {
    showSport() {
      return this.sport
    },
    showPhysicalFitness() {
      return this.physicalFitness
    },
    showPhysiology() {
      return this.physiology
    },
    showData() {
      return this.allCount
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.getDashboardData()
      this.getTypeRecord()
      this.getPlaceRecord()
    },
    async getDashboardData() {
      try {
        const res = await getProviderDataCount()
        this.sport = res.data.Sport_org ? res.data.Sport_org : 0
        this.physicalFitness = res.data.PhysicalFitness_org ? res.data.PhysicalFitness_org : 0
        this.physiology = res.data.Physiology_org ? res.data.Physiology_org : 0
        this.allCount = res.data.all_count_org ? res.data.all_count_org : 0
      
        this.sportPercentage = res.data.Sport_ratio
        this.physicalFitnessPercentage = res.data.PhysicalFitness_ratio
        this.physiologyPercentage = res.data.Physiology_ratio
      } catch (error) {
        console.error(error)
      }
    },

    async getTypeRecord() {
      try {
        const res = await getMainTypeRecord()
        const res_PhysicalFitness_type = res.data.find(item => item.PhysicalFitness_type)
        const res_Sport_type = res.data.find(item => item.Sport_type)
      
        this.physicalFitnessTpyePercentage = res_PhysicalFitness_type.PhysicalFitness_type[0].ratio
        this.physicalFitnessTpyeNUM = res_PhysicalFitness_type.PhysicalFitness_type[0].org_type
        this.sportTypePercentage = res_Sport_type.Sport_type[0].ratio
        this.sportTypeNUM = res_Sport_type.Sport_type[0].org_type
      
        const res_Sport = res.data.find(item => item.Sport)
        if (Object.keys(res_Sport.Sport[0]).length) {
          const SportData = res_Sport.Sport[0]
          Object.keys(SportData).forEach((key) => {
            this.sportTypeData.push({ 
              value: SportData[key],
              name: key
            })
          })
        }
        const res_PhysicalFitness = res.data.find(item => item.PhysicalFitness)
        if (Object.keys(res_PhysicalFitness.PhysicalFitness[0]).length) {
          const PhysicalFitnessData = res_PhysicalFitness.PhysicalFitness[0]
          Object.keys(PhysicalFitnessData).forEach((key) => {
            this.physicalFitnessTypeData.push({ 
              value: PhysicalFitnessData[key],
              name: key
            })
          })
        }
      } catch (error) {
        console.error(error)
      }
    },
    async getPlaceRecord() {
      try {
        const res = await getPlaceRatio()
        const apiResData = res.data
        this.physicalPlaceFitnessPercentage = apiResData.PhysicalFitness_place.place_ratio
        this.physicalFitnessPlaceNUM = apiResData.PhysicalFitness_place.org_place
        this.sportPlacePercentage = apiResData.Sport_place.place_ratio
        this.sportPlaceNUM = apiResData.Sport_place.org_place
        this.physiologyPlacePercentage = apiResData.Physiology_place.place_ratio
        this.physiologyPlaceNUM = apiResData.Physiology_place.org_place
      
        this.physicalFitnessPlaceData = apiResData.PhysicalFitness
        this.physiologyPlaceData = apiResData.Physiology
        this.sportPlaceData = apiResData.Sport
      } catch (error) {
        console.error(error)
      }
    },
    filterChartData(item) {
      const result = item.filter((item) => {
        return item.value !== 0
      }).map((item) => {
        item.name = item.place_zh
        return item
      })
      return result
    },
    handleResize() {
      this.$nextTick(() => {
        this.isPhone = window.innerWidth < 768
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/styles/index.scss";

.Provider-dashboard{
  margin: 0 auto;
  max-width: 1200px;
  text-align: left;
  font-size: 1rem;
  .dask-pad-only{
    @include hidden-by-phone;
  }
  .phone-only{
    @include hidden-by-desk;
    @include hidden-by-pad;
  }
  .el-card{
    border: none;
    margin: 15px 0;
  }
  .overview-block{
    
    position: relative;
    margin-bottom: 50px;
    .overview-card{
      background:url(../../../assets/images/dashboard/provider_dashboard_overview.png) bottom right no-repeat;
      background-size: 25%;
      background-color: white;
      padding: 50px;
      height: 260px;
      @media (max-width: 576px){
        padding: 20px;
        height: initial;
      }

      .overview-desc, .overview-unit{
        font-size: 1.5rem;
        .overview-unit-text-span{
          padding-right: 20px;
        }
      }
      .overview-num{
        font-size: 2rem;
      }
      @media (max-width: 576px) { 
        .overview-desc, .overview-unit{
          text-align: center;
        }
        .overview-desc{
          padding-bottom: 30px;
        }
      }
      @media (max-width: 576px) { 
        .overview-unit-text{
          font-size: 0.875rem;
        }
      }
    }
    @media (max-width: 1200px) and (min-width: 769px){
      .overview-card{
        background-size: 30%;
      }
    }
    @media (max-width: 768px) {
      .overview-card{
        background:none;
        background-color: white;
      }
    }
    .overview-datatype-row{
      position: absolute;
      bottom: -50px;
      width: 100%;
      .sport-card{
        .overview-datatype-card-body{
          background-color: $sport_yellow;
        }
      }
      .physicalFitness-card{
        .overview-datatype-card-body{
          background-color: $physicalFitness_turquoise;
        }
      }
      .physiology-card{
        .overview-datatype-card-body{
          background-color: $physiology_green;
        }
      }
      .datatype-count, .el-card{
        border-radius: 5px;
        .overview-datatype-card{
          margin: 0;
        }
        .overview-datatype-card-img{
          background-color: white;
          text-align: center;
          .image{
            width: 92%;
            margin:auto;
            display: block;
            @media (max-width: 768px) and (min-width: 577px){
              width: 40%;
            }
            @media (max-width: 576px) and (min-width: 411px){
              width: 60%;
            }
            @media (max-width: 410px) {
              width: 90%;
            }
          }
        }
        .overview-datatype-card-body{
          padding: 20px;
          font-size: 1rem;
          .datatype-number{
            padding: 5px 0;
            font-size: 2rem;
          }
        }
        @media (max-width: 1200px) and (min-width: 769px){
          .overview-datatype-card-body{
            padding: 20px 10px;
          }
        }
      }
    }
    @media (max-width: 768px) {
      .overview-datatype-row{
        position: initial;
        display: block;
        .datatype-count .overview-datatype-card{
          margin-bottom: 10px;
        }
      }
    }
  }
  
  .maintype-container{
    .chart-card{
      padding: 50px;
      padding-top: 30px;
      @media (max-width: 576px){
        padding: 30px;
      }
    }
    .chart-title{
      text-align: center;
      font-size: 1.25rem;
      font-weight: bold;
    }
  }

  .physiology-container{
    .chart-card{
      padding: 30px 300px 50px 300px;
      @media (max-width: 1200px){
        padding: 30px 100px 50px 100px;
      }
      @media (max-width: 576px){
        padding: 30px;
      }
    }
  }
}
</style>
