import { render, staticRenderFns } from "./PhysicalFitnessPlaceRatioPieChart.vue?vue&type=template&id=28c43d22&scoped=true&"
import script from "./PhysicalFitnessPlaceRatioPieChart.vue?vue&type=script&lang=js&"
export * from "./PhysicalFitnessPlaceRatioPieChart.vue?vue&type=script&lang=js&"
import style0 from "./PhysicalFitnessPlaceRatioPieChart.vue?vue&type=style&index=0&id=28c43d22&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28c43d22",
  null
  
)

export default component.exports