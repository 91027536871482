<template>
  <el-table
    class="overall-table"
    :data="tableData"
    height="540"
    style="width: 100%"
    stripe
  >
    <el-table-column
      type="index"
      width="50"
    />
    <el-table-column
      sortable
      prop="organization"
      :label="$t(`Dashboard.organization_name`)"
      width="240"
      align="center"
    />
    <el-table-column
      sortable
      prop="org_city"
      :label="$t(`Dashboard.org_city`)"
      align="right"
    />
    <el-table-column
      sortable
      prop="total"
      :label="$t(`Dashboard.total`)"
      align="right"
    />
    <el-table-column
      sortable
      prop="PhysicalFitness"
      :label="$t(`Dashboard.PhysicalFitness`)"
      align="right"
    />
    <el-table-column
      sortable
      prop="Sport"
      :label="$t(`Dashboard.Sport`)"
      align="right"
    />
    <el-table-column
      sortable
      prop="Physiology"
      :label="$t(`Dashboard.Physiology`)"
      align="right"
    />
    <el-table-column
      width="80"
    />
  </el-table>
</template>

<script>

export default {
  name: 'OverallTable', // admin dashboard
  props: {
    overallSummary: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tableData: []
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.getOverAllTableData()
    },
    
    async getOverAllTableData() {
      this.tableData = this.overallSummary.map((item) => {
        const newObj = {
          ...item,
          total: item.Organization_total
        }
        return newObj
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";

.overall-table {
    border-top: 1px solid #dcdfe6 !important;
}

</style>
