<template>
  <div>
    <v-chart
      v-if="ischartData"
      class="chart"
      :option="option"
      autoresize
    />
    <el-empty
      v-else
      slot="empty"
      class="chart"
      :description="$t('general.NoData')"
    />
  </div>
</template>

<script>
import VChart from 'vue-echarts'
import { use } from 'echarts/core'
import { DatasetComponent, TooltipComponent, LegendComponent, GridComponent, DataZoomComponent } from 'echarts/components'
import { BarChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'

use([DatasetComponent, TooltipComponent, LegendComponent, GridComponent, BarChart, CanvasRenderer, DataZoomComponent])

export default {
  name: 'OverviewAllBarChart', // admin dashboard
  components: {
    'v-chart': VChart
  },
  props: {
    overallSummary: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      orgList: [],
      SportList: [],
      physiologyList: [],
      PhysicalFitnessList: [],
      ischartData: false
    }
  },
  computed: {
    option() {
      return {
        color: ['#066471', '#00c2cb', '#fac858'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: this.orgList
        },
        dataZoom: [
          {
            id: 'dataZoomX',
            type: 'slider',
            xAxisIndex: [0],
            filterMode: 'none'
          },
          {
            id: 'dataZoomY',
            type: 'slider',
            yAxisIndex: [0],
            filterMode: 'none'
          }
        ],
        series: [
          {
            name: this.$t('Dashboard.PhysicalFitness'),
            type: 'bar',
            cursor: 'default',
            stack: 'total',
            // label: { show: true }, // 顯示 bar 上的數字
            emphasis: {
              focus: 'series'
            },
            data: this.PhysicalFitnessList
          },
          {
            name: this.$t('Dashboard.Physiology'),
            type: 'bar',
            cursor: 'default',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data: this.physiologyList
          },
          {
            name: this.$t('Dashboard.Sport'),
            type: 'bar',
            cursor: 'default',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data: this.SportList
          }
        ]
      }
    }
  },
  watch: {
    overallSummary(newVal) {
      this.updateChartData(newVal)
    }
  },
  mounted() {
    this.updateChartData(this.overallSummary)
  },
  methods: {
    updateChartData(overallSummary) {
      if (overallSummary.length) {
        // 即使所有組織資料都為 0, 依舊顯示圖表 
        this.ischartData = true

        const sortDataList = overallSummary.map((item) => {
          const newObj = {
            ...item
          }
          return newObj
        })
        sortDataList.sort(function(x, y) {
          const a = x.Organization_total
          const b = y.Organization_total
          return a - b
        })
        sortDataList.forEach((item) => {
          this.orgList.push(item.organization)
          this.SportList.push(item.Sport)
          this.physiologyList.push(item.Physiology)
          this.PhysicalFitnessList.push(item.PhysicalFitness)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  height: 100%;
  width: 100%;
  min-height: 550px;
  ::v-deep canvas{
    cursor: default;
  }
}
</style>
