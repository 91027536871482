<template>
  <div class="dashboard">
    <template v-if="isAdminTeam">
      <Admin />
    </template>
    <template v-if="isOrgTeam">
      <Provider />
    </template>
    <template v-if="isConsumer">
      <Consumer />
    </template>
  </div>
</template>

<script>
import 'element-ui/lib/theme-chalk/display.css'
import { MemberRole, FormatNumber } from '@/Mixins'

import Admin from './components/Admin.vue'
import Provider from './components/Provider.vue'
import Consumer from './components/Consumer.vue'

export default {
  name: 'Dashboard',
  components: {
    Admin,
    Provider,
    Consumer
  },
  mixins: [MemberRole, FormatNumber]
  
}

</script>

<style scoped lang="scss">
@import "src/styles/index.scss";

.dashboard{
  width: 90%;
  margin: 0 auto;
  text-align: center;
}
</style>
