<template>
  <div>
    <div class="progress-title-block">
      {{ ChartPercentageTitle }}
      <span class="progress-totle-number">{{ ChartNum }}</span>
    </div>
    <template v-if="PercentageNum">
      <div class="subinfo-text">
        {{ NumeratorText}} / {{ DenominatorText }} ({{ unitText }})
      </div>
      <el-progress
        :text-inside="true"
        :stroke-width="22"
        :percentage="PercentageNum"
        :color="datatypeColor"
      />
    </template>
    <template v-else>
      {{ $t('Dashboard.LessThanOne') }}
    </template>
  </div>
</template>

<script>
import 'element-ui/lib/theme-chalk/display.css'
import { Resize } from '@/Mixins'

export default {
  name: 'ProviderChartPercentageBar',
  mixins: [Resize],
  props: {
    ChartPercentageTitle: {
      type: String,
      default: ''
    },
    datatypeColor: {
      type: String,
      default: ''
    },
    ChartNum: {
      type: Number,
      default: 0
    },
    PercentageNum: {
      type: Number,
      default: 0
    },
    NumeratorText: {
      type: String,
      default: ''
    },
    DenominatorText: {
      type: String,
      default: ''
    },
    unitText: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/styles/index.scss";
.progress-title-block{
  line-height: 3;
}
.subinfo-text{
  font-size: 0.875rem;
  color: $text;
  line-height: 3;
}
.progress-totle-number{
  float: right;
  font-size: 2rem;
}
</style>
