<template>
  <div>
    <v-chart
      v-if="chartData.length > 0" 
      class="chart"
      :option="option"
      autoresize
    />
    <el-empty
      v-else
      slot="empty"
      class="chart"
      :description="$t('general.NoData')"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VChart from 'vue-echarts'
import { use } from 'echarts/core'
import { TooltipComponent, LegendComponent } from 'echarts/components'
import { PieChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'

use([TooltipComponent, LegendComponent, PieChart, CanvasRenderer])

export default {
  name: 'PhysiologyPlacePieChart', // consumer and provider dashboard
  components: {
    'v-chart': VChart
  },
  props: {
    isPhone: {
      type: Boolean,
      default: false
    },
    physiologyPlaceData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      chartData: []
    }
  },
  computed: {
    ...mapGetters(['language']),
    edgeDistanceWidth () {
      return this.isPhone ? '5' : '20'
    },
    option() {
      return {
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c} ({d}%)',
          position: [10, 150]
        },
        color: [
          '#c6bdc2',
          '#066471',
          '#00c2cb',
          '#bbddeb',
          '#ffc738', 
          '#51859e',
          '#77797b', 
          '#1e5146',
          '#007949',
          '#3bbc61',
          '#10a99d',
          '#6ca14c',
          '#9c94fa'
        ],
        series: [
          {
            name: '上傳資料占比',
            type: 'pie',
            cursor: 'default',
            radius: '50%',
            // data: [
            //   { value: 15492, name: '未填' },
            //   { value: 8757, name: '運動中心' },
            //   { value: 2752, name: '健身房' },
            //   { value: 759, name: '學校' },
            //   { value: 408, name: '社區' },
            //   { value: 142, name: '職場' },
            //   { value: 72, name: '衛生單位' }
            // ],
            data: this.chartData,
            label: {
              position: 'outer',
              alignTo: 'edge',
              edgeDistance: this.edgeDistanceWidth
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
    }
  },
  watch: {
    language() { 
      this.updateChartData(this.physiologyPlaceData) 
    },
    physiologyPlaceData: {
      immediate: true,
      handler(newVal, oldVal) {
        this.updateChartData(newVal)
      }
    }
  },
  mounted() {
    this.updateChartData(this.physiologyPlaceData)
  },
  methods: {
    updateChartData(physiologyPlaceData) {
      this.chartData = physiologyPlaceData.filter((item) => {
        return item.value !== 0
      }).map((item) => {
        item.name = this.$t(`PlaceType.${item.place}`)
        return item
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  height: 100%;
  width: 100%;
  min-height: 400px;
  ::v-deep canvas{
    cursor: default;
  }
  @media (max-width: 576px){
    min-height: 300px;
  }
}
</style>
