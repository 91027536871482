<template>
  <div class="admin-dashboard">
    <!-- 總覽 -->
    <el-row>
      <el-card shadow="hover">
        <div class="overview">
          <div class="card-title">
            {{ $t('Dashboard.Overview') }}
            <span>{{ formatToLocaleString(allCount) }} </span>
            <span class="title-right">
              <el-switch
                v-model="isChart"
                :active-text="$t('Dashboard.chart')"
                :inactive-text="$t('Dashboard.table')"
              />
              <el-button type="info" plain icon="el-icon-download" @click="downloadExcel"> Excel </el-button>
            </span>
          </div>
          <template v-if="showTable">
            <OverallTable
              :overall-summary="overallSummary"  
            /> 
          </template>
          <template v-else>
            <el-col :xs="24" :md="12">
              <OverviewAllBarChart
                :overall-summary="overallSummary"
              />
              <div class="subtitle">{{ $t('Dashboard.DataTypeRecords') }}</div>
            </el-col>
            <el-col :xs="24" :md="12">
              <OverviewRationPieChart
                :overall-ratio="overallRatio"
              />
              <div class="subtitle">{{ $t('Dashboard.UploadDataRatio') }}</div>
            </el-col>
          </template>
        </div>
      </el-card>
    </el-row>
    <!-- 運動數據 -->
    <el-row>
      <el-card shadow="hover">
        <div class="sport">
          <div class="card-title">
            {{ $t('Dashboard.SportsData') }}
            <span>
              {{ formatToLocaleString(sport) }}
            </span>
          </div>
          <el-col :xs="24" :md="12">
            <SportUploadRatioPieChart
              :sport-upload-data="sportUploadData"
            />
            <div class="subtitle">{{ $t('Dashboard.UploadDataRatio') }}</div>
          </el-col>
          <el-col :xs="24" :md="12">
            <SportTypeRatioPieChart />
            <div class="subtitle">{{ $t('Dashboard.DataTypeRatio') }}</div>
          </el-col>
        </div>
      </el-card>
    </el-row>
    <!-- 體適能數據 -->
    <el-row>
      <el-card shadow="hover">
        <div class="physicalFitness">
          <div class="card-title">
            {{ $t('Dashboard.PhysicalFitnessData') }}
            <span>
              {{ formatToLocaleString(physicalFitness) }}
            </span>
          </div>
          <el-col :xs="24" :md="12">
            <PhysicalFitnessUploadRatioPieChart
              :fitness-upload-data="fitnessUploadData"
            />
            <div class="subtitle">{{ $t('Dashboard.UploadDataRatio') }}</div>
          </el-col>
          <el-col :xs="24" :md="12" class="physicalFitness-right">
            <PhysicalFitnessPlaceRatioPieChart
              :fitness-place-data="fitnessPlaceData"
            />
            <div class="subtitle">{{ $t('Dashboard.PlaceTypeDataRatio') }}</div>
          </el-col>
        </div>
      </el-card>
    </el-row>
   
    <!-- 生理數據 -->
    <el-row>
      <el-card shadow="hover">
        <div class="health">
          <div class="card-title">
            {{ $t('Dashboard.PhysiologyData') }}
            <span>
              {{ formatToLocaleString(physiology) }}
            </span>
          </div>
          <el-col :xs="24" :md="12" class="health-left">
            <HealthUploadRatioPieChart 
              :physiology-upload-data="physiologyUploadData"
            />
            <div class="subtitle">{{ $t('Dashboard.UploadDataRatio') }}</div>
          </el-col>
          <el-col :xs="24" :md="12" class="health-right">
            <HealthPlaceRatioPieChart 
              :physiology-place-data="physiologyPlaceData"
            />
            <div class="subtitle">{{ $t('Dashboard.PlaceTypeDataRatio') }}</div>
          </el-col>
        </div>
      </el-card>
    </el-row>
  </div>
</template>

<script>
import * as XLSX from 'xlsx'
import 'element-ui/lib/theme-chalk/display.css'
import { MemberRole, FormatNumber } from '@/Mixins'

import OverviewAllBarChart from '../chart/OverviewAllBarChart.vue'
import OverviewRationPieChart from '../chart/OverviewRationPieChart.vue'
import PhysicalFitnessUploadRatioPieChart from '../chart/PhysicalFitnessUploadRatioPieChart.vue'
import PhysicalFitnessPlaceRatioPieChart from '../chart/PhysicalFitnessPlaceRatioPieChart.vue'
import SportUploadRatioPieChart from '../chart/SportUploadRatioPieChart.vue'
import SportTypeRatioPieChart from '../chart/SportTypeRatioPieChart.vue'
import HealthUploadRatioPieChart from '../chart/HealthUploadRatioPieChart.vue'
import HealthPlaceRatioPieChart from '../chart/HealthPlaceRatioPieChart.vue'

import OverallTable from './OverallTable.vue'

import { getOverview, getUploadRatio, getPlaceRatio } from '@/api/dashboard'
import { getDataCount } from '@/api/data'

export default {
  name: 'AdminDashboard',
  components: {
    OverviewAllBarChart,
    OverviewRationPieChart,
    PhysicalFitnessUploadRatioPieChart,
    PhysicalFitnessPlaceRatioPieChart,
    SportUploadRatioPieChart,
    SportTypeRatioPieChart,
    HealthUploadRatioPieChart,
    HealthPlaceRatioPieChart,
    OverallTable
  },
  mixins: [MemberRole, FormatNumber],
  data () {
    return {
      physiology: 0,
      physicalFitness: 0,
      sport: 0,
      allCount: 0,
      isChart: true,
      overallSummary: [],
      overallRatio: [],

      // for chart
      physiologyUploadData: [],
      fitnessUploadData: [],
      sportUploadData: [],
      physiologyPlaceData: [],
      fitnessPlaceData: []
    }
  },
  computed: {
    showTable() {
      return !this.isChart
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.getSummaryData()
      this.getOverAllTableData()
      this.getUploadRatio()
      this.getPlaceRatio()
    },
    async getSummaryData() {
      try {
        const res = await getDataCount()
        this.physiology = res.data.Physiology ? res.data.Physiology : 0
        this.physicalFitness = res.data.PhysicalFitness ? res.data.PhysicalFitness : 0
        this.sport = res.data.Sport ? res.data.Sport : 0
        this.allCount = res.data.all_count ? res.data.all_count : 0
      } catch (error) {
        console.error(error)
      }
    },
    async getOverAllTableData() {
      try {
        const res = await getOverview()
        this.overallSummary = res.data[0].overview_all_records
        this.overallRatio = res.data[0].overview_ratio
      } catch (error) {
        console.error(error)
      }
    },
    async getUploadRatio() {
      try {
        const res = await getUploadRatio()
        const apiResData = res.data

        this.physiologyUploadData = this.filterChartData(apiResData.Physiology)
        this.fitnessUploadData = this.filterChartData(apiResData.PhysicalFitness)
        this.sportUploadData = this.filterChartData(apiResData.Sport)
      } catch (error) {
        console.error(error)
      }
    },
    async getPlaceRatio() {
      try {
        const res = await getPlaceRatio()
        const apiResData = res.data

        this.physiologyPlaceData = this.filterPlaceChartData(apiResData.Physiology)
        this.fitnessPlaceData = this.filterPlaceChartData(apiResData.PhysicalFitness)
      } catch (error) {
        console.error(error)
      }
    },
    async downloadExcel() {
      const apiResult = await getOverview()
      let result = apiResult.data[0].overview_all_records
      result = this.dataCleanExcel(result)
      await this.onDowdloadExcel(result)
    },
    filterChartData(item) {
      const result = item.filter((item) => {
        return item.value !== 0
      }).map((item) => {
        item.name = item.organization
        return item
      })
      return result
    },
    filterPlaceChartData(item) {
      const result = item.filter((item) => {
        return item.value !== 0
      }).map((item) => {
        item.name = item.place_zh
        return item
      })
      return result
    },
    dataCleanExcel(fetchData) {
      const exportColumn = {
        organization_name: { column: ['organization'], root: true },
        org_city: { column: ['org_city'], root: true },
        total: { column: ['Organization_total'], root: true },
        PhysicalFitnessData: { column: ['PhysicalFitness'], root: true },
        SportsData: { column: ['Sport'], root: true },
        HealthData: { column: ['Physiology'], root: true }
      }
      const result = []
      fetchData.forEach((item, idx) => {
        const itemResult = { [this.$t('Dashboard.no')]: idx + 1 }
        Object.keys(exportColumn).forEach((column) => {
          let resultArray
          if (exportColumn[column]['root']) {
            resultArray = this.formatColumns(exportColumn[column].column, item)
            resultArray = resultArray.join()
          }
          itemResult[this.$t('Dashboard.' + column)] = resultArray
        })
        result.push(itemResult)
      })
      return result
    },
    formatColumns(column, checkDataset) {
      return column.map((subColumn) =>
        this.confirmExist(checkDataset, subColumn))
    },
    confirmExist(data, column) {
      if (!data) {
        return null
      }
      return data[column]
    },
    onDowdloadExcel(result) {
      const worksheet = XLSX.utils.json_to_sheet(result)
      this.$excel(worksheet, 'SportData')
    }
  }
}

</script>

<style scoped lang="scss">
@import "src/styles/index.scss";

.admin-dashboard{
  .el-card{
    border: none;
    margin: 15px 0;
    color: $text_default;
  }

  .card-title{
    font-size: 24px;
    text-align: left;
    padding-left: 20px;

    span{
      font-size: 20px;
      color: $info;
    }
    .orgName{
      color: $text_default;
    }

    .title-right{
      float: right;
      padding-right: 10px;

      .el-button {
        border: 1px solid transparent;
        background-color: transparent;
      }

      .el-button:hover {
        border: 1px solid $info;
        color: $info;
        font-weight: bold;
      }
    }
  }

  .card-title, .subtitle{
    height: 100px;
    line-height: 50px;
  }

}
</style>
