<template>
  <el-row>
    <el-card 
      :body-style="{ padding: '0px' }"
      :class="['datatype-card',datatypeContainer]"
      shadow="hover"
    >
      <div class="section-h2 datatype-title">{{ datatypeTitle }}</div>
      <el-row class="datatype-body">
        <el-col 
          :xs="{span:24}"
          :sm="{span:13}"
        >
          <div>
            <span class="datatype-number">
              <animated-number
                :value="provideDataCount"
                :format-value="formatToLocaleString"
                :duration="2000"
              />
            </span>
            <span>{{ $t(`Dashboard.ContributeCount`) }}</span>
          </div>
          <div>
            <div class="progress-title-block">{{ $t(`Dashboard.ContributePercentage`) }}</div>
            <template v-if="providePercentage">
              <div class="subinfo-text">
                {{ $t(`Dashboard.ContributeDataCount`) }} / {{ $t(`Dashboard.ServiceDataCount`) }}
              </div>
              <el-progress 
                :text-inside="true"
                :stroke-width="22"
                :percentage="providePercentage"
                :color="datatypeColor"
              />
            </template>
            <template v-else>
              {{ $t('Dashboard.LessThanOne') }}
            </template>
          </div>
        </el-col>
      </el-row>
    </el-card>
  </el-row>
</template>

<script>
import 'element-ui/lib/theme-chalk/display.css'
import { MemberRole, FormatNumber, Resize } from '@/Mixins'

import AnimatedNumber from 'animated-number-vue'

export default {
  name: 'ProviderDatatypeCard',
  components: {
    AnimatedNumber
  },
  mixins: [MemberRole, FormatNumber, Resize],
  props: {
    datatypeContainer: {
      type: String,
      default: ''
    },
    datatypeTitle: {
      type: String,
      default: ''
    },
    datatypeColor: {
      type: String,
      default: ''
    },
    provideDataCount: {
      type: Number,
      default: 0
    },
    providePercentage: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/styles/index.scss";
.maintype-container{
    .datatype-title{
      margin: 0;
      line-height: 2.5;
      text-align: center;
      border-radius: 0 0 40px 40px;
    }
    .datatype-body{
      padding: 50px;
      @media (max-width: 576px){
        padding: 30px;
      }
      .datatype-number{
        font-size: 4rem;
        padding-right: 30px;
        @media (max-width: 576px){
          font-size: 2rem;
        }
      }
    }
    .progress-title-block{
      line-height: 3;
    }
    .subinfo-text{
      font-size: 0.875rem;
      color: $text;
      line-height: 3;
    }
    .progress-totle-number{
      float: right;
      font-size: 2rem;
    }
  }
 .sport-container{
    .datatype-card{
      background:url(../../../assets/images/dashboard/provider_sport.png) bottom right no-repeat;
      background-size: 30%;
      background-color: white;
      @media (max-width: 576px){
        background: none;
        background-color: white;
      }
    }
    .datatype-title{
      background: linear-gradient(to bottom, #FCDF9F, #ffb000);
    }
  }
  .physicalFitness-container{
    .datatype-card{
      background:url(../../../assets/images/dashboard/provider_physicalFitness.png) bottom right no-repeat;
      background-size: 30%;
      background-color: white;
      @media (max-width: 576px){
        background: none;
        background-color: white;
      }
    }
    .datatype-title{
      background: linear-gradient(to bottom, #92e9ed, #00c2cb);
    }
  }
  .physiology-container{
    .datatype-card{
      background:url(../../../assets/images/dashboard/provider_physiology.png) bottom right no-repeat;
      background-size: 30%;
      background-color: white;
      @media (max-width: 576px){
        background: none;
        background-color: white;
      }
    }
    .datatype-title{
      background: linear-gradient(to bottom, #d8f196, #95c11f);
    }
  }
</style>
