<template>
  <div>
    <v-chart
      class="chart"
      :option="option"
      autoresize
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VChart from 'vue-echarts'
import { use } from 'echarts/core'
import { TooltipComponent, LegendComponent } from 'echarts/components'
import { PieChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'

use([TooltipComponent, LegendComponent, PieChart, CanvasRenderer])

export default {
  name: 'DatatypeRingChart', // consumer dashboard
  components: {
    'v-chart': VChart
  },
  props: {
    dataType: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      chartData: []
    }
  },
  computed: {
    ...mapGetters(['language']),
    edgeDistanceWidth () {
      return this.isPhone ? '5' : '6' // special padding
    },
    option() {
      return {
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c} ({d}%)'
          // position: [30, 70] // special position
        },
        color: [
          '#ffb000',
          '#00c2cb',
          '#95c11f'
        ],
        series: [
          {
            name: '三種數據占比',
            type: 'pie',
            cursor: 'default',
            radius: ['40%', '70%'],
            avoidLabelOverlap: true, //  延伸線
            data: this.chartData,
            // data: [
            //   { value: 359081, name: '運動' },
            //   { value: 36701, name: '生理' },
            //   { value: 114773, name: '體適能' }
            // ],
            label: {
              position: 'outer',
              alignTo: 'edge',
              edgeDistance: this.edgeDistanceWidth
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
    }
  },
  watch: {
    language() { 
      this.updateChartData(this.dataType) 
    },
    dataType: {
      immediate: true,
      handler(newVal, oldVal) {
        this.updateChartData(newVal)
      }
    }
  },
  mounted() {
    this.updateChartData(this.dataType)
  },
  methods: {
    updateChartData(dataType) {
      this.chartData = dataType.map(([name, value]) => {
        return { 
          name: this.$t(`general.${name}`),
          value
        }
      })
    }
  }
  
}
</script>

<style lang="scss" scoped>
.chart {
  height: 100%;
  width: 100%;
  min-height: 300px; // 200 fhashion
  ::v-deep canvas{
    cursor: default;
  }
  @media (max-width: 576px){
    min-height: 300px;
  }
}
</style>
