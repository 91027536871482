<template>
  <div>
    <v-chart
      v-if="chartData.length > 0"
      class="chart"
      :option="option"
      autoresize
    />
    <el-empty
      v-else
      slot="empty"
      class="chart"
      :description="$t('general.NoData')"
    />
  </div>
</template>

<script>
import VChart from 'vue-echarts'
import { use } from 'echarts/core'
import { TooltipComponent, LegendComponent } from 'echarts/components'
import { PieChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'

use([TooltipComponent, LegendComponent, PieChart, CanvasRenderer])

export default {
  name: 'OverviewRationPieChart', // admin dashboard
  components: {
    'v-chart': VChart
  },
  props: {
    overallRatio: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      chartData: []
    }
  },
  computed: {
    option() {
      return {
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c} ({d}%)'
        },
        color: [
          '#c6bdc2',
          '#066471',
          '#00c2cb',
          '#bbddeb',
          '#ffc738', 
          '#51859e',
          '#77797b', 
          '#1e5146',
          '#007949',
          '#3bbc61',
          '#10a99d',
          '#6ca14c',
          '#9c94fa'
        ],
        series: [
          {
            type: 'pie',
            cursor: 'default',
            radius: '50%',
            // data: [
            //   { value: 1048, name: '中正運動中心' },
            //   { value: 735, name: '長庚醫院' },
            //   { value: 580, name: '松山健康中心' }
            // ],
            // 顯示數字
            // label: {
            //   formatter: '{b}:{c}'
            // },
            data: this.chartData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
    }
  },
  watch: {
    overallRatio(newVal) {
      this.updateChartData(newVal)
    }
  },
  mounted() {
    this.updateChartData(this.overallRatio)
  },
  methods: {
    updateChartData(overallRatio) {
      this.chartData = overallRatio.filter((item) => {
        return item.value !== 0
      }).map((item) => {
        item.name = item.organization
        return item
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  height: 100%;
  width: 100%;
  min-height: 550px;
  ::v-deep canvas{
    cursor: default;
  }
}
</style>
